@import 'variables.custom';
@import '../layout/variables';

.gori-teal-btn {
  border-color: #00d3bc;
  background-color: #ffffff;
  color: #5e6278;

  &:hover {
    background-color: #00d3bc;
    color: $white;
  }
}

.input-custom {
  position: relative;
  font-size: 1.25rem;

  .input-custom__input.is-invalid + .input-custom__append--right,
  .input-custom__input.is-valid + .input-custom__append--right {
    right: 40px;
  }

  &__append {
    color: $gray-600;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &--right {
      right: 10px;
    }
    &--left {
      left: 10px;
    }
  }
}

.bg-page {
  background-color: $page-bg;
}
